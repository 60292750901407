import React from "react";
import { ListGroup, Container, Row } from "react-bootstrap";
// AU LOGO IMPORTS
import atasLogo from "../assets/img/atas-logo.png";
import catoLogo from "../assets/img/CATO_MasterLogo_2020.png";
import sunloverLogo from "../assets/img/sunlover.png";
import tdLogo from "../assets/img/td.png";
import ninLogo from "../assets/img/nin.png";
// NZ LOGO IMPORTS
import hloLogo from "../assets/img/hlologo.svg";
import taanzLogo from "../assets/img/taanz.png";

export const footerLogos = () => {
  switch (process.env.REACT_APP_MODE) {
    case "agent": //AGENTS.VIVA
    case "testing": //TRN
    case "local": //LOCALHOST
    case "consumer":
      return (
        <Container fluid className="d-flex bg-white">
          <div className="mr-auto d-none d-lg-block">
            <ListGroup horizontal>
              <ListGroup.Item className="footer-logos">
                <img src={atasLogo} alt="atas accredited" />
              </ListGroup.Item>
              <ListGroup.Item className="footer-logos">
                <img src={catoLogo} alt="cato accredited" />
              </ListGroup.Item>
            </ListGroup>
          </div>
          <div className="d-none d-lg-block">
            <ListGroup horizontal>
              <ListGroup.Item className="footer-logos">
                <a href="https://www.sunloverholidays.com.au/" target="_blank">
                  <img src={sunloverLogo} alt="Sunlover Holidays" />
                </a>
              </ListGroup.Item>
              {/* <ListGroup.Item className="footer-logos">
                <a href="http://www.territorydiscoveries.com/" target="_blank">
                  <img src={tdLogo} alt="Territory Discovery" />
                </a>
              </ListGroup.Item> */}
              <ListGroup.Item className="footer-logos">
                <a href="https://www.needitnow.com.au/" target="_blank">
                  <img src={ninLogo} alt="Need it Now" />
                </a>
              </ListGroup.Item>
            </ListGroup>
          </div>
        </Container>
      ); //AU VIVA HOLS CONSUMER
    case "goholsnz": //GO HOLS NZ CONSUMER
      return (
        <Container fluid className="d-flex bg-white">
          <div className="mr-auto d-none d-lg-block">
            <ListGroup horizontal>
              <ListGroup.Item className="footer-logos">
                <img src={taanzLogo} alt="taanz accredited" />
              </ListGroup.Item>
            </ListGroup>
          </div>
          <div className="d-none d-lg-block">
            <ListGroup horizontal>
              <ListGroup.Item className="footer-logos">
                <a href="https://helloworld.co.nz/" target="_blank">
                  <img src={hloLogo} alt="Helloworld Travel" />
                </a>
              </ListGroup.Item>
            </ListGroup>
          </div>
        </Container>
      );
  }
};
