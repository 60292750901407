import React from "react";
import ImageGalleryLightbox from "../elements/ImageGalleryLightbox";
import { v1 as uuid } from "uuid";
import DealCard from "../elements/DealCard";
import CartCard from "../elements/CartCard";
import Parser from "html-react-parser";
import { Link } from "react-router-dom";
import { Container, Row, Col, ListGroup, Card, Spinner } from "react-bootstrap";
import moment from "moment";
// import Slider from "react-slick";
import _ from "lodash";
import logo from "../assets/img/viva_vertical_logo.png";

// export const setBackground = (setImage, defaults, path) => {
//   console.log("in");
//   console.log(defaults);
//   if (defaults) {
//     let res = defaults.view_2.filter(obj => obj.field_page_path == path);
//     console.log(res);
//     res[0] && setImage(path, res[0].field_fc_background_image);
//   }
// };

// export const setBackgroundStyles = image => {
//   console.log(image);

//   const bgImgDefault =
//     "linear-gradient(rgba(0, 0, 0, 0.02), rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0), rgba(0, 0, 0, 0)),";

//   let styles = {
//     backgroundImage: `${bgImgDefault} url(${image})`,
//     backgroundAttachment: "fixed",
//     backgroundSize: "cover",
//     backgroundRepeat: "no-repeat"
//   };

//   _.map(styles, (style, id) => {
//     console.log(style);
//     document.body.style[id] = style;
//   });
// };

//ENQUIRY MSG TEMPLATE
export const emailBody = (data) => {
  const message = `New Enquiry Received... <br /><br />

   *** Sender Details: *** <br />
   First Name: ${data.firstName} <br />
   Last Name: ${data.lastName} <br />
   Phone Number: ${data.phone} <br />
   Email: ${data.email} <br />
   Preferred Method of Contact: ${data.preferredContact} <br /><br />

   *** Enquiry Regarding *** <br />
   Package: ${data.subject} <br />
   Package URL: ${data.dealURL} <br />
   Enquiry Type: ${data.enqType} <br />
   Preferred Airline: ${data.prefAirline} <br />
   Departure City: ${data.departCity} <br />
   No of Nights: ${data.noNights} <br />
   Preferred Travel Dates..<br />
      Start: ${moment(data.travelDates.startDate).format(
        "dddd, Do MMMM YYYY"
      )} <br />
      End: ${moment(data.travelDates.endDate).format(
        "dddd, Do MMMM YYYY"
      )} <br />
   Total Adults: ${data.noAdult} <br />
   Total Children: ${data.noChild} <br />
   Children Ages: ${data.childAges} <br /><br />

   Discount Code: ${data.discCode} <br /><br />

   *** Additional Arrangements *** <br /> ${data.additionalarrangements} <br /><br />

   *** Message *** <br /> ${data.message}
  `;
  return message;
};

export const emailReport = (
  tpError,
  bookingReply,
  paymentReply,
  formValues
) => {
  const message = tpError
    ? `*** Tourplan Add Booking Failed *** <br />
  Error Response: ${tpError} <br /><br />

  *** Sale Details : *** <br />
  Package: ${formValues.package} <br />
  Total Adults: ${formValues.noAdult}  <br />
  Sale Price: ${formValues.totPrice} <br />
  Option Code: ${formValues.optCode} <br />
  From Date: ${formValues.fromDate} <br />
  To Date: ${formValues.toDate} <br /><br />

  *** Customer Details : *** <br />
  Booked By: ${formValues.booker_title} ${formValues.booker_firstName} ${formValues.booker_lastName}<br />
  Phone: ${formValues.phoneCode} ${formValues.phone} <br />
  Email: ${formValues.email} <br />`
    : `New Booking Request Notification... <br /><br />

  *** Tourplan Details: *** <br />
  Booking Ref: ${bookingReply.ref} <br /><br />

  *** eWay Payment : *** <br />
  Authorisation Code: ${paymentReply.data.authorisationCode} <br />
  Transaction Id: ${paymentReply.data.transactionID} <br />
  Errors: ${paymentReply.data.errors} <br /><br />

  *** Customer Details : *** <br />
  Booked By: ${formValues.booker_title} ${formValues.booker_firstName} ${formValues.booker_lastName}<br />
  Phone: ${formValues.phoneCode} ${formValues.phone} <br />
  Email: ${formValues.email} <br /><br />

  *** Sale Details : *** <br />
  Package: ${formValues.package} <br />
  Total Adults: ${formValues.noAdult}  <br />
  Sale Price: ${formValues.totPrice} <br />
  Option Code: ${formValues.optCode} <br />
  From Date: ${formValues.fromDate} <br />
  To Date: ${formValues.toDate} <br />
  `;
  return message;
};

//BOOKING MSG TEMPLATE
export const emailBodyBooking = (pay, book, tp, deal) => {
  let includes = deal.field_includes_text.replace(
    /<(\w+)(?:\s+\w+="[^"]+(?:"\$[^"]+"[^"]+)?")*>\s*<\/\1>/g,
    ""
  );
  includes = includes.replace(/<\/?span[^>]*>/g, "");

  const message = `
  <!DOCTYPE html>
  <html lang="en">
  <head>
  <meta http-equiv="content-type" content="text/html; charset=UTF-8" />
  <meta name="viewport" content="width=device-width, initial-scale=1.0" />
  <title>Viva Holidays Booking Confirmation</title>
  </head>
  <body>

  <img width="100" alt="Viva Holidays" src="https://vivaholidays.com.au/static/media/viva_vertical_logo.c9a91164.png" title="Viva Holidays"> <br /><br />

  Dear ${book.booker_firstName},<br /><br />

  Thank you! Your booking ${
    tp.ref
  } is confirmed. Please find below your booking confirmation details; <br /><br />

  Confirmation Reference: ${tp.ref} <br />
  Package: ${book.package} <br />
  Date From: ${moment(book.fromDate).format("dddd, Do MMMM YYYY")} <br />
  Date To: ${moment(book.toDate).format("dddd, Do MMMM YYYY")} <br />
  Inclusions:
  ${includes}<br />
  Total Price: $${book.totPrice}.00 inc GST <br /><br />

    Thank you for booking with Viva Holidays! Should you have any questions please contact our reservations team on 1300 TRAVEL (1300 872 835). <br /><br />

    Kind Regards, <br />
    Viva Holidays <br /><br />

    Viva Holidays Pty Ltd <br />
    ABN 78 634 662 294
    </body>
</html>
  `;
  return message.replace(/\s+/g, " ");
};

//function to render destination list for packages
export const renderLocations = (pkg) => {
  if (pkg.field__campaign_destination.includes(",")) {
    let destinations = pkg.field__campaign_destination.split(",");
    return _.chain(destinations)
      .map((dest, index) => {
        return (
          <>
            <Link
              to={`/destinations/${dest
                .trim()
                .replace(/\s+/g, "-")
                .toLowerCase()}`}
            >
              <i className="fas fa-map-marker-alt pr-1"></i>
              {dest}
              <span>{index !== 3 ? ", " : ""}</span>
            </Link>
          </>
        );
      })
      .take(4)
      .value();
  }
  return (
    <Link
      to={`/destinations/${pkg.field__campaign_destination
        .trim()
        .replace(/\s+/g, "-")
        .toLowerCase()}`}
    >
      <i className="fas fa-map-marker-alt pr-1"></i>
      {pkg.field__campaign_destination}
    </Link>
  );
};

export const renderDestGuide = (destGuideImage, destGuideLink) => {
  return (
    <div className="col-sm-12 col-md-12 col-lg-3 mt-4 destinationGuide">
      <h5 className="text-uppercase mb-3 text-center" title="Destination Guide">
        Destination Guide
      </h5>
      <a href={destGuideLink} target="_blank">
        <Card.Img className="deal-card" src={destGuideImage} />
      </a>
    </div>
  );
};

export const renderFileList = (flyersPath, flyersTitle) => {
  return (
    <div className="col-sm-12 col-md-12 col-lg-9 mt-4">
      <h5 className="text-uppercase mb-3 text-center" title="Downloads">
        Downloads
      </h5>
      {_.map(flyersTitle, (val, id) => {
        return (
          <div className="downloads" key={uuid()}>
            <ul className="list-group">
              <li className="list-group-item list-group-item-light">
                <a className="d-flex" href={flyersPath[id]} target="_blank">
                  <span>{Parser(val.trim())}</span>{" "}
                  <i className="fas fa-download ml-auto"></i>
                </a>
              </li>
            </ul>
          </div>
        );
      })}
    </div>
  );
};

export const renderCurrentPackageList = (id, packages, basePath) => {
  let length = _.size(packages) < 5 ? 3 : 5;

  // let settings = {
  //   dots: true,
  //   infinite: true,
  //   speed: 500,
  //   slidesToShow: length,
  //   slidesToScroll: 5,
  //   vertical: true
  // };

  // const pkg = _.filter(packages, deal => {
  //   return deal.nid !== id;
  // });

  //console.log(pkg);

  const content = (
    <div>
      <h3 className="mt-2 mb-4 text-uppercase" title="Current Packages">
        Related Packages
      </h3>
      {/* <Slider {...settings}> */}
      {_.chain(packages)
        .filter((deal) => {
          return deal.nid !== id && deal.field_package_category !== "/deals";
        })
        .map((deal) => {
          return (
            <DealCard
              pkg={deal}
              key={uuid()}
              image={deal.field_main_image}
              basePath={deal.field_package_category}
              path={deal.field_path}
            />
          );
        })
        .take(3)
        .value()}
      {/* </Slider> */}
    </div>
  );

  return content;
};

export const renderCurrentDealList = (id, packages, basePath, pkgTitle) => {
  const pkg = _.filter(packages, (deal) => {
    return deal.nid_1 !== id;
  });

  const content = (
    <div>
      <h3 className="mt-2 mb-4 text-uppercase" title="Current Packages">
        Related Deals
      </h3>
      {/* <Slider {...settings}> */}
      {_.chain(pkg)
        .filter((deal) => {
          return deal.nid !== id && deal.field_package_category !== "/deals";
        })
        .map((deal) => {
          let pkgBase = Parser(pkgTitle)
            .trim()
            .replace(/[$!®@%:,'&*-]/g, "");
          let pkgPath = pkgBase.replace(/\s+/g, "-").toLowerCase();

          let dealTitle = deal.field_vc_deal_title
            ? deal.field_vc_deal_title
            : deal.title;

          let dealBase = Parser(dealTitle)
            .trim()
            .replace(/[$!®@%:,'&*-]/g, "");
          let dealPath = dealBase.replace(/\s+/g, "-").toLowerCase();

          let path = `/packages/${pkgPath}/${dealPath}/${deal.nid_1}`;

          return (
            <DealCard
              pkg={deal}
              key={uuid()}
              image={deal.field_main_image}
              path={path}
              noLoc={true}
            />
          );
        })
        .take(3)
        .value()}
      {/* </Slider> */}
    </div>
  );

  return content;
};

export const renderBrandPartners = (images, alt) => {
  let gallery = images.split(",");
  let altText = alt.split(",");

  const content = (
    <Container className="mt-5 text-center">
      <div title="Image Gallery">SUPPORTING</div>
      <div className="d-lg-inline-flex">
        {_.map(gallery, (imageURL, key) => {
          return (
            <ListGroup.Item className="brand-logos p-5" key={uuid()}>
              <img src={imageURL} alt={altText[key]} />
            </ListGroup.Item>
          );
        })}
      </div>
    </Container>
  );

  return content;
};

export const renderImageGallery = (images, captions = "", alt = "") => {
  const content = (
    <Container className="mt-5 text-center" name="imageGallery" key={uuid()}>
      {/* <hr className="hr-short-50" /> */}
      <h3 className="text-uppercase mb-3" title="Image Gallery">
        Image Gallery
      </h3>
      <div className="col-md-12">
        <ImageGalleryLightbox
          gallery={images.split(",")}
          alt={alt.split(",")}
          cap={captions.split(",")}
        />
      </div>
    </Container>
  );
  return content;
};

export const renderContentCards = (view, premPage, path, pkgId) => {
  const styles = premPage
    ? "col-xs-12 col-sm-12 col-md-6" //premium grid
    : "col-xs-12 col-sm-12 col-md-12 fit-content-image"; //stacked

  if (_.isEmpty(view)) {
    return <></>;
  }

  const content = _.chain(view)
    .map((deal) => {
      let title = deal.field_vc_deal_title
        ? deal.field_vc_deal_title
        : deal.title;

      title = Parser(title)
        .trim()
        .replace(/[$!®@%:,'&*-]/g, "");
      let dealPath = title.replace(/\s+/g, "-").toLowerCase();

      return (
        <div className={styles} key={deal.nid_1}>
          <DealCard
            pkg={deal}
            image={deal.field_main_image}
            //path is pkg source + pkg id + formatted pkg title
            path={`${path}/${dealPath}/${deal.nid_1}`}
            noLoc={true}
          />
        </div>
      );
    })
    .value();

  return content;
};

export const renderShoppingCart = (items, view, premPage, path, pkgId) => {
  const styles = premPage
    ? "col-xs-12 col-sm-12 col-md-6" //premium grid
    : "col-xs-12 col-sm-12 col-md-12 fit-content-image"; //stacked

  if (_.isEmpty(view)) {
    return <></>;
  }

  const content = _.chain(view)
    .map((deal) => {
      let title = Parser(deal.title)
        .trim()
        .replace(/[$!®@%:,'&*-]/g, "");
      let dealPath = title.replace(/\s+/g, "-").toLowerCase();
      return (
        <div className={styles} key={deal.nid_1}>
          <CartCard
            pkg={deal}
            image={deal.field_main_image}
            //path is pkg source + pkg id + formatted pkg title
            path={`${path}/${dealPath}/${deal.nid_1}`}
            noLoc={true}
            items={items}
          />
        </div>
      );
    })
    .value();

  return content;
};

export const packageFiltering = (packages, basePath) => {
  if (basePath.includes("exclusive-deals")) {
    return _.filter(packages, "field_exclusive_deals", 1);
  } else if (basePath.includes("/deals")) {
    return _.filter(packages, "field_package_category", "/packages");
  } else {
    return _.filter(packages, (item) => {
      return item.field_package_category === basePath;
    });
  }
};

export const loader = (message) => {
  return (
    <Container>
      <Spinner animation="border" variant="danger" role="status">
        <span className="sr-only">
          <i className="fas fa-spinner fa-3x">Loading</i>
        </span>
      </Spinner>
      {message && <span>&nbsp;{message}</span>}
    </Container>
  );
};

/**
 * check if all options are true
 * @param {} arr
 */
export function checkAvailability(arr) {
  let allAvailble = arr.every(checkAvail);

  return allAvailble;
}

/**
 * check availability
 * @param {*} val
 */
function checkAvail(val) {
  return val >= 1;
}

export function getDates(startDate, stopDate) {
  var dateArray = [];
  var currentDate = moment(startDate);
  var stopDate = moment(stopDate);

  while (currentDate <= stopDate) {
    let current = moment(currentDate).format("YYYY-MM-DD");
    let format = new Date(
      current.split("-")[0],
      current.split("-")[1] - 1,
      current.split("-")[2]
    );

    dateArray.push(format);
    currentDate = moment(currentDate).add(1, "days");
  }
  return dateArray;
}

/**
 * coiunt difference between 2 dates
 * @param {*} date1
 * @param {*} date2
 */
export function daysdifference(date1, date2) {
  var ONEDAY = 1000 * 60 * 60 * 24;
  var date1_ms = date1.getTime();
  var date2_ms = date2.getTime();

  var diff = date2_ms - date1_ms;
  var difference_ms = 0;
  var diffDays = 0;
  if (diff > 0) {
    difference_ms = Math.abs(diff);
    diffDays = Math.round(difference_ms / ONEDAY);
  }

  return diffDays;
}

/**
 * enable form buttons after validation
 * @param {*} form
 * @param {*} buttons
 * @param {*} num_inputs
 */
export function enableFormButtons(form, buttons, min_num_inputs) {
  let availEnable = 0;

  for (let i = 0; i < form.length; i++) {
    if (form[i].tagName == "INPUT") {
      if (
        form[i].name == "Adults" ||
        form[i].name == "Adults" ||
        form[i].placeholder == "To" ||
        form[i].placeholder == "From"
      ) {
        if (form[i].type == "text" && form[i].value != "") {
          availEnable++;
        }

        if (availEnable > min_num_inputs) {
          buttons.forEach(function (object, i) {
            object.removeAttribute("disabled");
          });
        }
      }
    }
  }
}

/**
 * get an array of dates
 * @param {*} arr
 */
export function getBlockedDates(arr, num_night) {
  let blockeddatesarr = [];

  arr.forEach(function (item, i) {
    if (i > 0) {
      let start = new Date(
        arr[i - 1].split("_")[1].split("-")[0],
        arr[i - 1].split("_")[1].split("-")[1] - 1,
        (
          Number(arr[i - 1].split("_")[1].split("-")[2]) -
          Number(num_night) +
          1
        ).toString()
      );
      let end = new Date(
        arr[i].split("_")[0].split("-")[0],
        arr[i].split("_")[0].split("-")[1] - 1,
        (Number(arr[i].split("_")[0].split("-")[2]) - 1).toString()
      );

      let endDates = new Date(
        arr[i - 1].split("_")[1].split("-")[0],
        arr[i - 1].split("_")[1].split("-")[1] - 1,
        (Number(arr[i - 1].split("_")[1].split("-")[2]) + 1).toString()
      );

      // console.log("endDates");

      // console.log(endDates);
      // console.log(start);

      blockeddatesarr.push(...getDates(start, end));
    }
  });

  return blockeddatesarr;
}

/**
 * set an array of passengers
 * @param {*} passengers
 * @param {*} e
 */
export function setPassengerList(passengers, e, passenger) {
  if (passengers.length > 0) {
    for (let i = 0; i < passengers.length; i++) {
      if (passengers.findIndex((x) => x.uuid === e.target.id) >= 0) {
        let index = passengers.findIndex((x) => x.uuid === e.target.id);

        passengers[index] = passenger;

        break;
      } else {
        passengers.push(passenger);
        break;
      }
    }
  } else {
    passengers.push(passenger);
  }

  return passengers;
}
